<template>
  <v-row>
    <v-col v-if="value.id" cols="12" lg="6">
         <v-text-field label="* Nova Senha"
                    dense
                    type="password"
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 6),
                      v => this.$validators.string.lessThanOrEquals(v, 20),
                  ]"
                    v-model.trim="value.senha"/>
    </v-col>
    <v-col v-if="value.id" cols="12" lg="6">
         <v-text-field label="* Confirmar Nova Senha"
                    dense
                    type="password"
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 6),
                      v => this.$validators.string.lessThanOrEquals(v, 20),
                      v => this.$validators.string.camposIguais(value.senha, value.senha2),
                  ]"
                    v-model.trim="value.senha2"/>
    </v-col>
  </v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";

export default {
  name: "FuncionarioSenhaForm",
  components: {LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        nivel: ItemDeDominio.NIVEL_ACESSO_USUARIO,
        flagProfessor: ItemDeDominio.FLG_PADRAO,
      }
    }
  }
}
</script>

<style scoped>

</style>