var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Dados pessoais")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('v-text-field',{attrs:{"label":"* Nome","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.nome),callback:function ($$v) {_vm.$set(_vm.value, "nome", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.nome"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"label":"CPF","dense":"","placeholder":"000.000.000-00","rules":[
                   this.$validators.string.cpf
                ]},model:{value:(_vm.value.cpf),callback:function ($$v) {_vm.$set(_vm.value, "cpf", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cpf"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Dados de acesso")]),_c('v-divider')],1),(this.nivelUsuarioLogado==='ADM')?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('lookup-dominio',{attrs:{"label":"* Nível de Acesso","hidden-clear":true,"hint":"Selecione o Nível de Acesso","type":_vm.lookups.nivel,"rules":[
                    this.$validators.notNullOrUndefined
                ]},model:{value:(_vm.value.nivel),callback:function ($$v) {_vm.$set(_vm.value, "nivel", $$v)},expression:"value.nivel"}})],1):_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_vm._v(" "+_vm._s("Nivel de Acesso")+" "),_c('br'),_vm._v(" "+_vm._s(_vm.value.descricaoNivel)+" ")]),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('lookup-dominio',{attrs:{"label":"* É Professor?","hidden-clear":true,"hint":"Informe se é Professor","type":_vm.lookups.flagProfessor,"rules":[
                    this.$validators.notNullOrUndefined
                ]},model:{value:(_vm.value.flagProfessor),callback:function ($$v) {_vm.$set(_vm.value, "flagProfessor", $$v)},expression:"value.flagProfessor"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"* Login","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.login),callback:function ($$v) {_vm.$set(_vm.value, "login", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.login"}})],1),(!_vm.value.id)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"* Senha","dense":"","type":"password","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.senha),callback:function ($$v) {_vm.$set(_vm.value, "senha", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.senha"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Dados de Contato")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"* Email","dense":"","rules":[
                    this.$validators.string.required,
                    this.$validators.string.email,
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 50); } ]},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"label":"* Celular","dense":"","rules":[
                    this.$validators.string.required ]},model:{value:(_vm.value.celular),callback:function ($$v) {_vm.$set(_vm.value, "celular", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.celular"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) ####-####'),expression:"'(##) ####-####'"}],attrs:{"label":"Telefone","dense":""},model:{value:(_vm.value.telefone),callback:function ($$v) {_vm.$set(_vm.value, "telefone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.telefone"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }