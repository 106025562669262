<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <create-edit-funcionario-dialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>
    <create-edit-senha-funcionario-dialog :item="item" v-model="editSenhaDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <funcionarios-data-table ref="dataTable"
                            @click:editSenha="editarSenha"
                             @click:edit="editar"
                             @click:remove="remover"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import FuncionariosDataTable from "../../components/shared/datatables/FuncionariosDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/funcionarios";
import CreateEditFuncionarioDialog from "../../components/shared/dialogs/CreateEditFuncionarioDialog";
import CreateEditSenhaFuncionarioDialog from "../../components/shared/dialogs/CreateEditSenhaFuncionarioDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {CreateEditFuncionarioDialog, CreateEditSenhaFuncionarioDialog, BaseView, FuncionariosDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      editSenhaDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Nome',
          column: 'nome',
          type: 'text'
        },
        {
          name: 'Login',
          column: 'login',
          type: 'text'
        },
        {
          name: 'Professor',
          column: 'flagProfessor',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.FLG_PADRAO
          })
        },
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.editDialogOpened = true;
    },
    editarSenha(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.editSenhaDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Funcionário removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover o Funcionário.`, {
          timeout: 6000
        })
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>